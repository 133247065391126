import Thumbnail, { ThumbnailProp } from "components/Thumbnail/thumbnail.component";
import { useCallback, useEffect, useRef, useState } from "react";
import "./chat-side-panel.styles.scss";
import ProgressBar from "components/ProgressBar/progress-bar.component";
import RetryIcon from "shared/assets/images/retry.svg";
import Icon from "components/Icon/icon.component";
import ChatFooter from "components/ChatFooter/chat-footer.component";
import { Conversation, Message as messageTwilio } from "@twilio/conversations";
import { toast } from "react-toastify";
import ChatList from "components/ChatList/chat-list.component";
import { Message as MessageType } from "components/ChatList/types";
import { batch, useSelector } from "react-redux";
import {
  getMessagingState,
  saveConversationId,
  saveConversationParticipants,
  saveTwilioConversationId,
} from "state/feature/messaging/messaging.slice";
import { useAppDispatch } from "state/store";
import {
  createConversationAsync,
  fetchAllMessagesOfAConversationAsync,
  getConversationIfPresentAsync,
  getConversationMessageCountAsync,
  sendMessageAsync,
} from "state/feature/messaging/messaging.action";
import { getCommonState } from "state/feature/common/common.slice";
import { getAuth } from "state/feature/auth/auth.slice";
import moment from "moment";
import { ISendMessage } from "shared/dtos/messaging";
import { ChatSidePanelProps } from "./type";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import { getContactState } from "state/feature/contact/contact.slice";
import { isMobile } from "react-device-detect";
import { USER_ROLE } from "shared/constant/role-constant";
import { IMessageReadParticipantsType } from "shared/types/messageReadParticipant.type";
import { Compose } from "./components/Compose/compose.container";
import Button from "components/Button/button.component";
import { ComposeRefType } from "shared/types/composeRef.type";
import { ISelectedDropdownValue } from "state/types/navigator.type";
import { OpenFrom } from "shared/enums/chat.enum";

type ComposeProps = {
  conversationState?: any;
  setConversation?: (value: any) => void;
  addItemInSelectedList?: (value: ISelectedDropdownValue[]) => void;
  selectedList?: ISelectedDropdownValue[];
  openFrom?: string;
};

const ChatSidePanel = ({
  conversationState,
  setConversation,
  addItemInSelectedList,
  selectedList = [],
  openFrom = "",
}: ChatSidePanelProps & ComposeProps) => {
  useAxiosAuthenticated();
  const appDispatch = useAppDispatch();
  const { isLoadingPatientList, isLoading } = useSelector(getMessagingState);
  const [previewThumbnail, setShowPreviewThumbnail] = useState<Array<ThumbnailProp>>([]);
  const [uploadPercentage, setUploadPercentage] = useState(-1);
  const [isCancelled, setIsCancelled] = useState(false);
  const cancelController = useRef<AbortController>();
  const [inputValue, setInputValue] = useState<string>("");
  const [fileToBeUploaded, setFileToBeUploaded] = useState<File | null>(null);
  const [updatedContoller, setUpdatedController] = useState<AbortController | null>(null);
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [messageCount, setMessageCount] = useState(0);
  const messagingState = useSelector(getMessagingState);
  const { selectedConversation, participants } = messagingState;
  const commonState = useSelector(getCommonState);
  const authState = useSelector(getAuth);
  const { twilioClientRef } = commonState.globalValues;
  const [unreadCount, setunreadCount] = useState<number>(Number.MAX_SAFE_INTEGER);
  const [readCount, setreadCount] = useState<number>(0);
  const [offset, setOffset] = useState(0);
  const CONVERSATIONREF = useRef<Conversation>();
  const unsubscribePartcipantUpdate = useRef<Conversation>();
  const unsubscribeMessageAdded = useRef<Conversation>();
  const [isFirstTimemsgSend, setisFirstTimemsgSend] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isMessageButtonClicked, setIsMessageButtonClicked] = useState(false);
  const [receiptDetailsForParticipants, setReceiptDetailsForParticipants] = useState<
    Array<IMessageReadParticipantsType>
  >([]);
  const [totalMessageCount, setTotalMessageCount] = useState(0);
  const [nextButtonDisable, setNextButtonDisable] = useState<boolean | undefined>(false);
  const composeRef = useRef<ComposeRefType>(null);

  useEffect(() => {
    cancelController.current = new AbortController();
  }, [updatedContoller]);

  const fetchAllMessages = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await appDispatch(
        fetchAllMessagesOfAConversationAsync({
          conversationID: selectedConversation.conversationId.toString(),
          offset,
        })
      ).unwrap();
      setLoading(false);
      if (data.messageDetails && data.messageDetails.length > 0) {
        setMessages((prev) => {
          return [...prev, ...data.messageDetails];
        });
      } else {
        setMessages([]);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [selectedConversation?.conversationId, offset, appDispatch]);

  const getUnreadMessages = useCallback(async () => {
    try {
      const conversation = CONVERSATIONREF.current as Conversation;
      const currentParticipants = await conversation.getParticipants();
      const count = (await conversation.getMessagesCount()) - 1;
      setTotalMessageCount(count);
      const filteredList = currentParticipants.filter((item: any) => item.identity !== authState.user.email);
      const maximumLastReadMessageIndex = Math.max(
        ...filteredList.map((item: any) => {
          if (item.lastReadMessageIndex === null) {
            return -1;
          }
          return item.lastReadMessageIndex;
        })
      );
      const newReceiptDetailsForParticipants = filteredList
        .filter((item: any) => item.lastReadMessageIndex != null)
        .map((item: any) => {
          const participant = participants?.find((obj) => obj?.email?.toLowerCase() === item?.identity?.toLowerCase());

          const newReadParticipantList = {
            lastReadMessageIndex: item.lastReadMessageIndex,
            lastReadTimestamp: item.lastReadTimestamp,
            identity: item.identity,
            sid: item.sid,
            messageIndex: -1,
            fullName: participant ? participant?.fullName : "",
            participantId: participant ? participant?.userID : "",
          };
          return newReadParticipantList;
        });
      const newUnreadCount =
        maximumLastReadMessageIndex === -1 ? Number.MAX_SAFE_INTEGER : count - maximumLastReadMessageIndex;
      setReceiptDetailsForParticipants(newReceiptDetailsForParticipants);
      setreadCount(maximumLastReadMessageIndex + 1);
      setunreadCount(newUnreadCount);
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createConversation = async () => {
    if (selectedList.length > 0 && !conversationState) {
      const selectedParticipants = selectedList.map(({ key }) => {
        return key.toString();
      });
      try {
        const { status, data } = await appDispatch(
          createConversationAsync({
            participantsUserId: [...selectedParticipants],
            subject: composeRef.current?.getSelectedPatient().label,
            ownerUserId: authState.user.navigatorId,
            patientId: composeRef.current?.getSelectedPatient().patientID,
          })
        ).unwrap();
        if (status === 201 && data.conversationID) {
          batch(() => {
            appDispatch(saveConversationId(data.conversationID));
            appDispatch(saveTwilioConversationId(data.conversationTwilioID));
            appDispatch(saveConversationParticipants(data.name));
          });
          setMessages([]);
          if (setConversation) {
            setConversation(data);
          }
          return data;
        }
      } catch (error: any) {
        const { data, status } = error;
        if (status === 400 && data?.conversationID === -1) {
          appDispatch(saveConversationId(data.conversationID));
          toast.error(data?.error, {
            toastId: "invalid-format",
            containerId: "main",
          });
        }
        if (status === 500) {
          toast.error("Something went wrong", {
            toastId: "invalid-format",
            containerId: "main",
          });
        }
        if (setConversation) {
          setConversation(null);
        }
      }
    } else {
      setMessages([]);
      if (setConversation) {
        setConversation(null);
      }
    }
  };

  const checkIfConversationPresent = useCallback(async () => {
    let selectedParticipants: string | any[] = [];
    if (selectedList && selectedList.length > 0) {
      selectedParticipants = selectedList.map(({ key }) => {
        return key.toString();
      });
    }

    const { status, data } = await appDispatch(
      getConversationIfPresentAsync({
        participantsUserId: selectedParticipants,
        ownerUserId: authState.user.navigatorId,
        subject: composeRef.current?.getSelectedPatient().label,
        patientId: composeRef.current?.getSelectedPatient().patientID,
      })
    ).unwrap();

    if (status === 200) {
      batch(() => {
        appDispatch(saveConversationId(data.conversationID));
        appDispatch(saveTwilioConversationId(data.conversationTwilioID));
        if (openFrom && openFrom === OpenFrom.COMPOSE) {
          appDispatch(saveConversationParticipants(data.name));
        }
      });
      if (setConversation) {
        setConversation(data);
      }
    } else if (status === 204) {
      createConversation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedList, messagingState?.userIdforMessage, appDispatch, authState.user.id, setConversation, openFrom]);

  const newMessageEvent = useCallback(async (messageAdded: messageTwilio) => {
    const conversation = CONVERSATIONREF.current as Conversation;
    const {
      body,
      attributes: { user, fileType, createdAt, fileName, fileSize },
      // eslint-disable-next-line @typescript-eslint/dot-notation
    } = messageAdded["state"];
    if (body && user._id !== authState.user.id) {
      conversation.setAllMessagesRead();
      const newMessage = {
        _id: messageAdded.sid,
        createdAt: moment(createdAt).toISOString(),
        message: {
          type: fileType.toLowerCase(),
          content: body as string,
          file: { type: fileType.toLowerCase() },
          fileName,
          fileSize,
        },
        user: {
          name: user.name,
          email: messageAdded.author,
          _id: user._id,
        },
      };
      setMessages((prev) => [newMessage, ...prev]);
    } else {
      if (isFirstTimemsgSend) {
        conversation.setAllMessagesRead();
        setisFirstTimemsgSend(false);
      }
      await getUnreadMessages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initializeTwilioEvents = useCallback(async () => {
    try {
      if (selectedConversation.twilioConversationId && twilioClientRef) {
        const conversation: Conversation = await twilioClientRef.getConversationBySid(
          selectedConversation.twilioConversationId
        );
        CONVERSATIONREF.current = conversation;
        conversation.setAllMessagesRead();
        await getUnreadMessages();
        unsubscribePartcipantUpdate.current = await conversation.addListener("participantUpdated", getUnreadMessages);
        unsubscribeMessageAdded.current = await conversation.addListener("messageAdded", newMessageEvent);
      }
    } catch (err) {
      toast.error("Something went wrong");
    }
  }, [selectedConversation, getUnreadMessages, newMessageEvent, twilioClientRef]);

  useEffect(() => {
    initializeTwilioEvents();
    return () => {
      CONVERSATIONREF.current = null as any;

      if (unsubscribeMessageAdded.current) unsubscribeMessageAdded.current.off("messageAdded", newMessageEvent);
      if (unsubscribePartcipantUpdate.current)
        unsubscribePartcipantUpdate.current.off("participantUpdated", getUnreadMessages);
    };
  }, [initializeTwilioEvents, newMessageEvent, getUnreadMessages]);

  useEffect(() => {
    if (selectedConversation.conversationId !== -1) {
      fetchAllMessages();
    }
  }, [selectedConversation.twilioConversationId, selectedConversation.conversationId, fetchAllMessages, offset]);

  useEffect(() => {
    if (selectedList.length >= 1) {
      checkIfConversationPresent();
    }
  }, [checkIfConversationPresent, selectedList]);

  const onRemoveThumbnail = (index: number) => {
    const copyOfThumbnails = [...previewThumbnail];
    copyOfThumbnails.splice(index, 1);
    setShowPreviewThumbnail(copyOfThumbnails);
    setFileToBeUploaded(null);
    setUploadPercentage(-1);
    setIsCancelled(false);
  };
  const cancelUpload = (controller?: AbortController) => {
    cancelController.current?.abort();
    if (controller) {
      setUpdatedController(controller);
    }
  };

  const onChangeInputValue = (value: string) => {
    setInputValue(value);
  };

  const onClickFileAttachment = async (e: any) => {
    const files: FileList = e.target.files;
    const file: File | null = files.item(0);
    if (file) {
      setIsCancelled(false);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (
          file?.type === "image/png" ||
          file?.type === "image/jpeg" ||
          file?.type === "image/jpg" ||
          file?.type === "application/pdf" ||
          file?.type === "application/msword" ||
          file?.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          const fileUrl = URL.createObjectURL(file);
          setFileToBeUploaded(file);
          setShowPreviewThumbnail((prev) => [
            ...prev,
            {
              url: fileUrl,
              type: file?.type,
              name: file?.name,
              size: file?.size,
            },
          ]);
        } else {
          toast.error("Please select a valid format which include PNG, JPG, PDF, WORD files.", {
            toastId: "invalid-format",
            containerId: "main",
          });
        }
      };
    }
  };

  const fetchMoreData = () => {
    setOffset(messages.length);
  };

  const getPayloadForSendMessage = async (metaData: {
    fileUploaded?: File | null;
    fileType?: string;
    textMessage?: string;
  }): Promise<ISendMessage> => {
    const { fileUploaded, fileType, textMessage } = metaData;
    let payload: ISendMessage;
    let newConversation: any = null;
    let convID = -1;
    let twillioConvID = "";

    if (messagingState.userIdforMessage || selectedList.length > 0) {
      if (!conversationState) {
        setLoading(true);
        newConversation = await createConversation();
      }
      const conversationToBeUsed = conversationState ? conversationState : newConversation;
      convID = conversationToBeUsed.conversationID;
      twillioConvID = conversationToBeUsed.conversationTwilioID;
    } else {
      convID = selectedConversation?.conversationId ?? 0;
      twillioConvID = selectedConversation?.twilioConversationId ?? "";
    }

    if (fileUploaded && fileType) {
      payload = {
        conversationId: convID ?? 0,
        conversationTwilioId: twillioConvID ?? "",
        message: "",
        messageType: fileType,
        messageOwnerEmail: authState.user.email,
        messageOwnerUserId: authState.user.id,
        fileUpload: fileUploaded,
        onUpload: (progressEvent: ProgressEvent) => {
          if (fileUploaded) {
            const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadPercentage(percentage);
            if (percentage === 100) {
              setTimeout(() => {
                setShowPreviewThumbnail([]);
                setUploadPercentage(-1);
              }, 1000);
            }
          }
        },
        abortController: cancelController.current,
      };
    } else {
      payload = {
        conversationId: convID ?? 0,
        conversationTwilioId: twillioConvID ?? "",
        message: textMessage,
        messageType: "TEXT",
        messageOwnerEmail: authState.user.email,
        messageOwnerUserId: authState.user.id,
        fileUpload: null,
        onUpload: (e: ProgressEvent) => {},
        abortController: cancelController.current,
      };
    }

    return payload;
  };

  const sendMessage = async (fileUploaded: File | null = null, fileType?: string, retry?: boolean) => {
    setUploadPercentage(0);
    const messagesContainerScroll = document.querySelector(".messages-container-scroll");
    if (!isMessageButtonClicked) {
      setIsMessageButtonClicked(true);
      try {
        if (fileToBeUploaded && fileUploaded && fileType) {
          const newMessage = {
            _id: new Date().getTime().toString(),
            createdAt: moment(new Date()).toISOString(),
            message: {
              type: fileType,
              content: URL.createObjectURL(fileToBeUploaded),
              file: { type: fileType },
              fileName: fileToBeUploaded.name,
              fileSize: fileToBeUploaded.size,
            },
            user: {
              name: authState.user.username,
              email: authState.user.email,
              _id: authState.user.id,
            },
          };
          const requestPayload: ISendMessage = await getPayloadForSendMessage({ fileUploaded, fileType });
          try {
            const res = await appDispatch(sendMessageAsync(requestPayload)).unwrap();
            if (loading) {
              setLoading(false);
            }
            if (res.data) {
              setFileToBeUploaded(null);
              setMessages((prev) => [newMessage, ...prev]);
              if (!loading) {
                setunreadCount((prev) => totalMessageCount - prev);
              }
              setInputValue("");
            }
            messagesContainerScroll?.scrollIntoView(false);
            if (messagesContainerScroll?.scrollTop === 0 && isMobile) {
              messagesContainerScroll.scrollTop = -1;
            }
            if (messagesContainerScroll?.scrollTop !== 0) {
              messagesContainerScroll?.scrollTo(0, 0);
            }
            setIsMessageButtonClicked(false);
          } catch (error: any) {
            const { status } = error;
            if (status === 500) {
              toast.error("Something went wrong", {
                toastId: "invalid-format",
                containerId: "main",
              });
            }
            if (loading) {
              setLoading(false);
            }
            setIsMessageButtonClicked(false);
            setFileToBeUploaded(null);
          }
        }

        if (inputValue.trim().length !== 0) {
          const newMessage = {
            _id: new Date().getTime().toString(),
            createdAt: moment(new Date()).toISOString(),
            message: {
              type: "TEXT",
              content: inputValue,
              file: { type: "TEXT" },
            },
            user: {
              name: authState.user.username,
              email: authState.user.email,
              _id: authState.user.id,
            },
          };
          const requestPayload: ISendMessage = await getPayloadForSendMessage({ textMessage: inputValue });
          try {
            const res = await appDispatch(sendMessageAsync(requestPayload)).unwrap();
            if (loading) {
              setLoading(false);
            }
            if (res.data) {
              if (loading) {
                setunreadCount((prev) => totalMessageCount - prev);
              }
              setMessages((prev) => [newMessage, ...prev]);
              setInputValue("");
            }
            messagesContainerScroll?.scrollIntoView(false);
            if (messagesContainerScroll?.scrollTop === 0 && isMobile) {
              messagesContainerScroll.scrollTop = -1;
            }
            if (messagesContainerScroll?.scrollTop !== 0) {
              messagesContainerScroll?.scrollTo(0, 0);
            }
            setIsMessageButtonClicked(false);
          } catch (error: any) {
            const { status } = error;
            if (status === 500) {
              toast.error("Something went wrong", {
                toastId: "invalid-format",
                containerId: "main",
              });
            }
            if (loading) {
              setLoading(false);
            }
            setIsMessageButtonClicked(false);
          }
        }
      } catch (error: any) {
        if (loading) {
          setLoading(false);
        }
        setIsMessageButtonClicked(false);
      }
    }
  };

  const getFileType = () => {
    const file = previewThumbnail[0];
    const fileType = file?.type.split("/")[0].toUpperCase().trim();
    if (!file) {
      return "TEXT";
    } else if (fileType === "IMAGE") {
      return "IMAGE";
    } else if (file.type === "application/pdf") {
      return "PDF";
    } else if (
      file?.type === "application/msword" ||
      file?.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      return "WORD";
    }
  };

  const getConversationMessageCount = useCallback(async () => {
    if (selectedConversation && selectedConversation.conversationId >= 0) {
      const { status, data } = await appDispatch(
        getConversationMessageCountAsync({ conversationId: selectedConversation.conversationId })
      ).unwrap();
      if (data && status === 200) {
        setMessageCount(data.count);
      }
    }
  }, [selectedConversation, appDispatch]);

  useEffect(() => {
    getConversationMessageCount();
  }, [getConversationMessageCount]);

  const checkIfGroupConversationExist = () => {
    if (authState.user.roles.includes(USER_ROLE.MANAGER)) {
      return (openFrom === "compose" && participants.length > 1) || participants.length > 2;
    } else {
      return participants.length > 1;
    }
  };

  const getMessageReadParticipants = (): Array<IMessageReadParticipantsType> | undefined => {
    if (checkIfGroupConversationExist() && receiptDetailsForParticipants.length > 0) {
      const newReceiptDetailsForParticipantsicipants = receiptDetailsForParticipants.map(
        (receiptDetailsForParticipant: IMessageReadParticipantsType) => {
          const lastReadMessageIndex = messages.findIndex((message, index) => {
            return (
              totalMessageCount - receiptDetailsForParticipant.lastReadMessageIndex <= index &&
              receiptDetailsForParticipant.participantId !== message.user?._id
            );
          });
          receiptDetailsForParticipant.messageIndex = lastReadMessageIndex;
          return receiptDetailsForParticipant;
        }
      );
      return newReceiptDetailsForParticipantsicipants;
    }
    return undefined;
  };

  const onNextButtonClick = () => {
    if (nextButtonDisable && !isLoading) {
      const loggedInUser = {
        key: authState.user.navigatorId,
        name: `${authState.user.firstName} ${authState.user.lastName}`,
        label: `${authState.user.firstName} ${authState.user.lastName}`,
        value: `${authState.user.firstName} ${authState.user.lastName}`,
        ...authState.user,
      };
      const participantList = [
        loggedInUser as ISelectedDropdownValue,
        ...(composeRef.current?.getSelectedPhysician() as ISelectedDropdownValue[]),
      ];
      if (composeRef.current?.includePatientInParticipationList()) {
        participantList.push(composeRef.current?.getSelectedPatient());
      } else {
        const index = participantList
          .map((item) => item.key)
          .indexOf(composeRef.current?.getSelectedPatient().key as string);
        if (index !== -1) {
          participantList.splice(index, 1);
        }
      }
      addItemInSelectedList?.(participantList);
    }
  };

  const getNextButtonDisableStatus = () => {
    if (composeRef.current?.getSelectedNavigator() && composeRef.current?.includePatientInParticipationList()) {
      return true;
    }
    return (
      composeRef.current?.getSelectedNavigator() &&
      composeRef.current?.getSelectedPatient() &&
      composeRef.current?.getSelectedPhysician().length > 0
    );
  };

  const callDisable = useCallback(() => {
    setNextButtonDisable(getNextButtonDisableStatus());
  }, []);

  useEffect(() => {
    if (!isLoadingPatientList && commonState.modal.isOpenChatModal.openFrom === OpenFrom.CONTACT) {
      setTimeout(() => document.getElementById("modal")?.click(), 1000);
    }
    window.addEventListener("click", callDisable);

    return () => {
      window.removeEventListener("click", callDisable);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingPatientList, commonState.modal.isOpenChatModal.openFrom]);

  const getInActiveParticipantInConversation = () => participants.find((el) => !el.isActive);

  const getInActiveInfoBar = () => {
    const inActiveParticipant = getInActiveParticipantInConversation();

    if (!inActiveParticipant) {
      return null;
    }

    if (checkIfGroupConversationExist()) {
      return (
        <div className="inactive-infobar-container">
          <span className="note">
            <span className="note-label">Note:</span> {inActiveParticipant.fullName} has been removed from the
            conversation
          </span>
        </div>
      );
    } else {
      return (
        <div className="inactive-infobar-container">
          <span className="note">
            <span className="note-label">Note:</span>
            The account for {inActiveParticipant.fullName} has been deleted. As a result this conversation cannot be
            continued.
          </span>
        </div>
      );
    }
  };

  const shouldHideChatFooter = () => !checkIfGroupConversationExist() && !!getInActiveParticipantInConversation();

  return (
    <div className={`chatSideContainer ${openFrom === OpenFrom.COMPOSE ? "compose" : ""}`}>
      {!conversationState && messagingState.selectedConversation.conversationId === -1 && (
        <div className="compose-message-section">
          <Compose ref={composeRef} />
        </div>
      )}
      <ChatList
        renderPlace="chatModal"
        messageDetails={messages}
        totalLength={messageCount}
        fetchMoreData={fetchMoreData}
        isPreviewThumbnailVisible={previewThumbnail.length > 0}
        cancelUpload={cancelUpload}
        sendMessage={sendMessage}
        selectedParticipants={messagingState.selectedConversation.participants}
        readCount={readCount}
        unreadCount={unreadCount}
        loading={loading}
        openFrom={openFrom}
        receiptDetailsForParticipants={getMessageReadParticipants()}
      />
      <div className={`footer-container ${shouldHideChatFooter() ? "hidden-footer-container" : ""}`}>
        <div className={`preview-section ${previewThumbnail.length > 0 ? "show" : "hidden"}`}>
          {previewThumbnail.length > 0 &&
            previewThumbnail.map((thumbnail, index) => {
              return (
                <>
                  <Thumbnail key={index} thumbnail={thumbnail} onRemove={onRemoveThumbnail} index={index} />
                  {uploadPercentage > 0 && (
                    <div className="progress-bar">
                      {isCancelled ? (
                        <div
                          className="cancel-upload"
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsCancelled(false);
                            sendMessage(fileToBeUploaded, getFileType(), true);
                          }}
                        >
                          <img src={RetryIcon} alt="retry" />
                        </div>
                      ) : (
                        <>
                          <ProgressBar percentage={uploadPercentage} />
                          <div
                            className="retry-upload"
                            onClick={(e) => {
                              e.stopPropagation();
                              cancelUpload(cancelController.current);
                              setIsCancelled(true);
                            }}
                          >
                            <Icon icon="close" size={14} />
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </>
              );
            })}
        </div>
        {!conversationState && messagingState.selectedConversation.conversationId === -1 && (
          <Button
            className="next-button green-button"
            text="Next"
            disabled={!nextButtonDisable || isLoading}
            onClick={onNextButtonClick}
          />
        )}
        {getInActiveInfoBar()}
        {(conversationState || messagingState.selectedConversation.conversationId !== -1) &&
          !shouldHideChatFooter() &&
          (openFrom === OpenFrom.CONTACT ||
            openFrom === OpenFrom.CONTACT_PHYSICIAN ||
            openFrom === OpenFrom.NOTIFICATION_PANEL ||
            openFrom === OpenFrom.COMPOSE ||
            openFrom === OpenFrom.TASK_MANAGEMENT ||
            openFrom === OpenFrom.EPISODE_DETAILS ||
            !authState.user.roles.includes(USER_ROLE.MANAGER) ||
            messagingState.participants?.some((participant) => participant.userID == authState.user.id)) && (
            <ChatFooter
              inputValue={inputValue}
              onChangeInputValue={onChangeInputValue}
              sendMessage={() => {
                if (fileToBeUploaded || inputValue.trim().length !== 0) {
                  sendMessage(fileToBeUploaded, getFileType(), false);
                }
              }}
              disabled={
                openFrom === OpenFrom.COMPOSE
                  ? selectedList.length === 0 || isMessageButtonClicked
                  : isMessageButtonClicked
              }
              onClickFileAttachment={onClickFileAttachment}
              previewThumbnail={previewThumbnail}
              placeholder="Maximum allowed length is 3200"
              isSendDisabled={(!fileToBeUploaded && inputValue.trim().length === 0) || isMessageButtonClicked}
            />
          )}
      </div>
    </div>
  );
};

export default ChatSidePanel;
