import moment from "moment";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  expandTaskBoard,
  getBrowserState,
  getCurrentPageTaskState,
  getTaskManagementState,
  removeTab,
  setIsCurrentTaskDirty,
  setIsCurrentTaskTouched,
  setLoadTaskList,
  setShouldPerformPostSubmitAction,
  setShowUnsavedChangesModal,
} from "state/feature/task-management/task-management.slice";
import Header from "../header/header.component";
import "./reminder-task.styles.scss";
import Button from "components/Button/button.component";
import { useAppDispatch } from "state/store";
import { getCommonState, setIsOpenDelayModal } from "state/feature/common/common.slice";
import { TaskStatus } from "shared/enums/tasks-status.enum";
import { getReminderTaskAsync, saveReminderTaskAsync } from "state/feature/reminder-task/reminder-task.action";
import { ReminderTaskFeilds } from "./common/reminder-task.constants";
import Error from "components/error-message/error-message.component";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";
import { getPatientFullName, getTaskHeaderText, isEmptyString } from "shared/methods/utilityFunctions";
import DelayModal from "components/Modal/delay-modal/delay-modal.container";
import { HandleDelay } from "./common/reminder-task.types";
import {
  getReminderTaskState,
  resetReminderState,
  setIsDirty,
  setIsValueChanged,
  updateReminder,
} from "state/feature/reminder-task/reminder-task.slice";
import { toast } from "react-toastify";
import { TOAST_MESSAGES } from "pages/login/common/constants";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import {
  getDelayAttempts,
  getTaskDelayStatuses,
  setLockState,
} from "state/feature/task-management/task-management.action";
import { ITab } from "state/types/task-management-slice.type";
import { PatientDetailsHeader } from "components/patient-details-header/patient-details-header.component";

const ReminderTaskContainer = () => {
  const { title, navigatorNotes } = ReminderTaskFeilds;
  const appDispatch = useAppDispatch();
  const { currentTab, pages } = useSelector(getBrowserState);
  const { intakeId, taskType, taskId } = currentTab!;
  const { patientDetails, taskPayload, delayTask, episode } = useSelector(getCurrentPageTaskState(taskId));
  const { currentUserProfile } = useSelector(getNavigatorState);
  const history = useHistory();
  const { featureFlags } = useSelector(getCommonState);
  const { isLoading, reminder, existingReminder, isDirty, isLoadingSubmitButton, isValueChanged } =
    useSelector(getReminderTaskState);
  const { redirectParams } = useSelector(getTaskManagementState);

  const showDelayNote =
    (!isLoading &&
      existingReminder &&
      existingReminder.taskStatusId !== TaskStatus.CLOSED &&
      existingReminder.taskStatusId !== TaskStatus.COMPLETED &&
      episode &&
      episode?.patientAndEpisodeDetails.coachPhone !== null &&
      delayTask.delayAttempts &&
      delayTask.delayAttempts?.length > 0 &&
      delayTask.delayAttempts[0]?.taskDelayStatusId === 1) ??
    false;

  const handleDelayButton = () => {
    appDispatch(expandTaskBoard(false));
    appDispatch(setIsOpenDelayModal(true));
  };

  const getPatientDetails: () => JSX.Element | undefined = useCallback(() => {
    if (patientDetails) {
      return <PatientDetailsHeader intakeId={intakeId} patientDetails={patientDetails} />;
    }
  }, [patientDetails, intakeId]);

  const isReminderTaskCompletedOrClosed = () =>
    existingReminder.taskStatusId !== null &&
    [TaskStatus.COMPLETED, TaskStatus.CLOSED].includes(existingReminder.taskStatusId);

  const onNavigatorNotesChange = (val: string) => {
    if (!/^\s/.test(val)) {
      appDispatch(updateReminder({ ...reminder, navigatorNotes: val }));
    }
  };

  const getIsReminderStatus = () => {
    if (!existingReminder.isReminderNotesOptional) {
      return reminder.navigatorNotes && !isEmptyString(reminder.navigatorNotes);
    }

    return existingReminder.isReminderNotesOptional;
  };

  const handleCompleteReminderTask = async (isSaveDraft = false) => {
    if (!isSaveDraft) {
      appDispatch(setIsDirty(true));
    }
    if (intakeId && taskId && currentUserProfile && (getIsReminderStatus() || isSaveDraft)) {
      const requestPayload = {
        intakeId: intakeId,
        taskId: taskId,
        notes: reminder.navigatorNotes,
        updateUser: getPatientFullName({
          firstName: currentUserProfile?.firstName,
          lastName: currentUserProfile?.lastName,
        }),
        updateUserId: currentUserProfile.id,
        isSaveDraft,
      };
      const resp = await appDispatch(saveReminderTaskAsync(requestPayload)).unwrap();
      if (resp.data && resp.statusCode === 200) {
        appDispatch(setIsValueChanged(false));
        appDispatch(setLoadTaskList(true));
        if (!isSaveDraft) {
          //write code to close the tab
          if (redirectParams) {
            const message =
              resp.message && !isEmptyString(resp.message) ? resp.message : TOAST_MESSAGES.DATA_SAVE_SUCCESSFULLY;
            setTimeout(() => {
              toast.success(message, { containerId: "main" });
            }, 1000);
          } else {
            if (!(featureFlags.taskManagement && featureFlags.taskManagementListView)) {
              history.goBack();
            } else {
              appDispatch(setShouldPerformPostSubmitAction({ taskId, performAction: true }));
            }
          }
        }
      } else {
        const message = resp?.message && !isEmptyString(resp?.message) ? resp?.message : TOAST_MESSAGES.ERROR;
        toast.error(message, { containerId: "main" });
      }
    }
  };

  const handleDelayReminderTask = (handleDelay: HandleDelay) => {
    if (taskId) {
      handleCompleteReminderTask(true)
        .then(() => {
          handleDelay(taskId);
          if (!(featureFlags.taskManagement && featureFlags.taskManagementListView)) {
            history.goBack();
          }
        })
        .catch(() => {});
    }
  };

  const isPageDirty = (tab: ITab) => {
    const page = pages.find((el) => el.taskId === tab.taskId);
    if (!page) {
      return false;
    }
    return page.isDirty;
  };

  const onCancel = () => {
    if (isPageDirty(currentTab!)) {
      appDispatch(setShowUnsavedChangesModal(true));
    } else {
      appDispatch(setLockState({ intakeId: currentTab!.intakeId, taskId: currentTab!.taskId, isLocked: false }));
      if (featureFlags.taskManagement && featureFlags.taskManagementListView) {
        appDispatch(removeTab(currentTab!.taskId));
        return;
      }
    }
  };

  useEffect(() => {
    return () => {
      appDispatch(resetReminderState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (intakeId && taskId && !taskPayload) {
      appDispatch(getReminderTaskAsync({ intakeId, taskId }));
      appDispatch(
        getDelayAttempts({
          intakeId: parseInt(intakeId),
          taskId: taskId,
        })
      );
    }
  }, [appDispatch, taskId, intakeId, taskPayload]);

  useEffect(() => {
    if (!delayTask.delayStatuses.length) {
      appDispatch(getTaskDelayStatuses(currentTab!.taskId));
    }
  }, [appDispatch, currentTab, delayTask.delayStatuses]);

  useEffect(() => {
    appDispatch(setIsCurrentTaskTouched({ taskId, isTouched: true }));
    if (isValueChanged) {
      appDispatch(setIsCurrentTaskDirty({ taskId, isDirty: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValueChanged]);

  return (
    <div id="reminder-task-container">
      <Header
        className="reminder-task-header"
        patientDetails={getPatientDetails()}
        title={getTaskHeaderText(taskType ?? "")}
        showDelayNote={showDelayNote}
      />
      <div id="form-container">
        {isLoading ? (
          <CommonState type={CommonStateType.LOADING} />
        ) : (
          <div className="reminder-fields-container">
            <div className="title-input-container reminder-input-field">
              <div className="title-label label">{title.label}</div>
              <input
                type="text"
                className="title-input disabled"
                maxLength={title.maxLength}
                placeholder={title.placeHolder}
                value={existingReminder.title}
                disabled={true}
              />
            </div>

            <div className="requests-notes-container reminder-input-field">
              <div className="requests-notes-label label">{"Request"}</div>
              <textarea
                className="requests-notes-input"
                cols={70}
                rows={5}
                maxLength={2000}
                placeholder={"-"}
                disabled={true}
                value={existingReminder?.requests ?? ""}
              />
            </div>
            <div className="navigator-notes-container reminder-input-field">
              <div className="navigator-notes-label label">{navigatorNotes.label}</div>
              <textarea
                className="navigator-notes-input"
                maxLength={navigatorNotes.maxLength}
                cols={70}
                rows={5}
                placeholder={navigatorNotes.placeHolder}
                disabled={isReminderTaskCompletedOrClosed()}
                value={reminder.navigatorNotes}
                onChange={(e) => {
                  onNavigatorNotesChange(e.currentTarget.value);
                }}
              />
              <div className="navigator-notes-length-container error-character-length-container">
                <div className="error-field">
                  {isDirty &&
                    !existingReminder.isReminderNotesOptional &&
                    !reminder.navigatorNotes &&
                    !isReminderTaskCompletedOrClosed() && <Error message="This field is required" />}
                </div>
                <div className="character-length">
                  {reminder.navigatorNotes && !isReminderTaskCompletedOrClosed() && (
                    <div className="text-length">{`${reminder.navigatorNotes.length}/${navigatorNotes.maxLength}`}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <footer className="reminder-task-footer-container">
        <div className="footer-buttons-div-container">
          <Button text={"Cancel"} className="cancel-button" onClick={onCancel} />
          {!isReminderTaskCompletedOrClosed() && (
            <Button text="Delay" className="cancel-button" onClick={handleDelayButton} />
          )}
          {!isReminderTaskCompletedOrClosed() && (
            <Button
              text="Done"
              className="submit-button"
              showLoader={isLoadingSubmitButton}
              onClick={() => handleCompleteReminderTask()}
              disabled={isLoading || isLoadingSubmitButton}
            />
          )}
        </div>
      </footer>
      <DelayModal handleSubmit={handleDelayReminderTask} />
    </div>
  );
};

export default ReminderTaskContainer;
