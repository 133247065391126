export const PatientEpisodeStatusSuccessMessage = "Episode status changed successfully";

export const PatientEpisodeStatus = {
  NEW: "New",
  PARTIAL_INTAKE: "Partial-Intake",
  CLOSED: "Closed",
};

export const careUtilizationEpisodeStatuses = {
  DECEASED: "Deceased",
  DISCHARGED: "Discharged",
};
