import { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotificationState,
  resetNotifications,
  setRunTasksPostAcknowledge,
} from "state/feature/notification/notification.slice";
import {
  acknowledgeNotification,
  getNotificationCountAsync,
  getNotificationsAsync,
} from "state/feature/notification/notification.action";
import ConversationLoading from "components/ConversationLoading/conversation-loading.component";
import { IGetNotificationsAsyncRequestPayload, INotification } from "state/types/notification-slice-type";
import NotificationRow from "pages/notification/components/notification-row/notification-row.component";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import NoNotification from "shared/assets/images/no-notifications.svg";
import "./notification-listing.styles.scss";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import { ActionType } from "pages/notification/common/action-type.enum";
import {
  saveConversationHeaderDetails,
  saveConversationId,
  saveConversationParticipants,
  saveTwilioConversationId,
  setLastMessageNotificationStatus,
} from "state/feature/messaging/messaging.slice";
import { getCommonState, setIsOpenChatModal } from "state/feature/common/common.slice";
import moment from "moment";
import { OpenFrom } from "shared/enums/chat.enum";

const NotificationListing = ({ userEmail }: { userEmail: string }) => {
  useAxiosAuthenticated();
  const {
    notifications,
    isLoadingNotifications,
    isNotificationDrawerOpen,
    runTasksPostAcknowledge,
    shouldRefreshNotifications,
    prevNotificationsCount,
  } = useSelector(getNotificationState);
  const dispatch = useDispatch();
  const { featureFlags } = useSelector(getCommonState);
  const [currentNotification, setCurrentNotification] = useState<INotification>();
  const [requestPayload, setRequestPayload] = useState<IGetNotificationsAsyncRequestPayload>({
    ownerUserId: userEmail,
    offset: 0,
    limit: 10,
  });

  const incrementPage = () => {
    setRequestPayload((prev) => {
      return {
        ...prev,
        offset: prev?.offset + prev?.limit,
      };
    });
  };

  const openConversation = (notification: INotification) => {
    const { payload, eventTitle, ownerUserId, internalId } = notification;
    dispatch(saveConversationId(payload.conversationId));
    dispatch(saveTwilioConversationId(payload.twilioConversationId));
    dispatch(saveConversationParticipants(eventTitle));
    dispatch(saveConversationHeaderDetails({ patientDOB: "", subject: "" }));

    if (!notification.isRead) {
      dispatch(
        acknowledgeNotification({
          ownerUserId,
          internalId,
          createdBefore: moment().toISOString(),
          runTasksPostAcknowledge: true,
        })
      );
    }
  };

  useEffect(() => {
    if (runTasksPostAcknowledge && currentNotification && currentNotification.actionType === ActionType.NEW_MESSAGE) {
      dispatch(setLastMessageNotificationStatus(currentNotification.payload.conversationId));
      dispatch(getNotificationCountAsync({ ownerUserId: userEmail }));
      dispatch(setRunTasksPostAcknowledge(false));
    }
  }, [runTasksPostAcknowledge, currentNotification, userEmail, dispatch]);

  const handleNotificationClick = (notification: INotification): void => {
    setCurrentNotification(notification);
    dispatch(setIsOpenChatModal({ isOpen: true, openFrom: OpenFrom.NOTIFICATION_PANEL }));
    switch (notification.actionType) {
      case ActionType.NEW_MESSAGE:
        openConversation(notification);
        break;
      case ActionType.MISSED_CALL:
        break;
      case ActionType.NEW_TOC:
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (featureFlags.inAppNotifications) {
      dispatch(getNotificationsAsync(requestPayload));
    }
  }, [isNotificationDrawerOpen, dispatch, featureFlags.inAppNotifications, requestPayload, shouldRefreshNotifications]);

  useEffect(() => {
    return () => {
      dispatch(resetNotifications());
    };
  }, [dispatch]);

  return notifications.length > 0 ? (
    <>
      <InfiniteScroll
        className="notification-scroll-container"
        next={() => {
          incrementPage();
        }}
        hasMore={notifications.length > 0 && prevNotificationsCount != notifications.length}
        loader={isLoadingNotifications ? <ConversationLoading /> : <></>}
        dataLength={notifications.length}
        height="100%"
        endMessage={
          <div className="end-message">
            <b>{"No more notifications available."}</b>
          </div>
        }
      >
        {notifications?.map((notification: INotification) => (
          <NotificationRow
            key={notification.id}
            notification={notification}
            onClick={() => handleNotificationClick(notification)}
          />
        ))}
      </InfiniteScroll>
    </>
  ) : (
    <>
      {isLoadingNotifications ? (
        <div className="loading-container">
          <CommonState type={CommonStateType.LOADING} />
        </div>
      ) : (
        <div className="empty-notifications-container">
          <img src={NoNotification} className="empty-notification-img" alt="no-notifications-img" />
          <p className="empty-title">No notification yet!</p>
          <p className="empty-content">Looks like there is nothing to notify you.</p>
        </div>
      )}
    </>
  );
};

export default NotificationListing;
