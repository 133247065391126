import Icon from "components/Icon/icon.component";
import Sort from "components/Sort/sort.component";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import { HeaderColumnInfo } from "pages/episodes/types";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { SortingOrderType } from "shared/enums/sorting-types.enums";
import { getEpisodesSlice } from "state/feature/episodes/episodes.slice";
import "./style.scss";
import Row from "./components/row/row.container";
import POCServiceModal from "components/Modal/poc-service-modal/poc-service-modal.container";
import { PlanOfCarePresentationProps } from "./type";
import POCDeleteConfirmationModal from "components/Modal/POCDeleteConfirmationModal/poc-delete-confirmation-modal.component";
import Toggle from "components/Toggle/toggle.component";
import { verifiedCareUtilization } from "./constant";
import { careUtilizationEpisodeStatuses } from "components/Modal/patient-status-modal/patient-status-modal-constants";

const PlanOfCarePresentation = (props: PlanOfCarePresentationProps) => {
  const {
    episodeDetailTabs: { planOfCare },
    isLoadingEpisodeDetailTabs: { planOfCare: isLoading },
  } = useSelector(getEpisodesSlice);
  const { episodeDetails } = useSelector(getEpisodesSlice);
  const statuses = Object.values(careUtilizationEpisodeStatuses);

  const { headerColumns, handleColumnSorting, handleSubmitForDeleteConfirmation, onToggleOnOff, onOffToggleTitle } =
    props;

  const renderSortIcons = (header: HeaderColumnInfo) => {
    if (!header.sortOrder) {
      return null;
    }
    return header.sortOrder === SortingOrderType.DEFAULT ? (
      <Sort />
    ) : (
      <Icon icon={header.sortOrder === SortingOrderType.ASC ? "sort-top" : "sort-bottom"} size={8} />
    );
  };

  return (
    <>
      <POCServiceModal />
      <POCDeleteConfirmationModal handleSubmitForDeleteConfirmation={handleSubmitForDeleteConfirmation} />
      {episodeDetails && (
        <div className="care-utilization-toggle">
          <Toggle
            toggleLeftTitle=""
            toggleRightTitle={verifiedCareUtilization}
            showNoTitle={false}
            activeToggleTitle={onOffToggleTitle}
            changeActiveToggle={onToggleOnOff}
            isDisabled={!statuses.includes(episodeDetails.status)}
          />
        </div>
      )}
      {!isLoading && planOfCare.length === 0 ? (
        <CommonState type={CommonStateType.NO_PLAN_OF_CARE_LIST_FOUND} />
      ) : (
        <div className="plan-of-care-table">
          <div className={`plan-of-care-table-header`}>
            {headerColumns.map((header, index) => (
              <div key={`${index}-${header.key}`} className={`column`}>
                <div
                  className={`column-content ${isLoading ? "disabled" : ""}  ${
                    header?.sortOrder ? "cursor-pointer" : ""
                  } `}
                  onClick={() => {
                    if (!isLoading && header?.sortOrder) {
                      handleColumnSorting(header);
                    }
                  }}
                >
                  <div className="content">{header.name}</div>
                  <span className="sort-icon">{!isLoading && header?.sortOrder && renderSortIcons(header)}</span>
                </div>
              </div>
            ))}
          </div>
          <div className={`plan-of-care-table-body `}>
            {isLoading && <CommonState type={CommonStateType.LOADING} />}
            {!isLoading && planOfCare?.length > 0 ? (
              <InfiniteScroll
                next={() => {}}
                hasMore={false}
                loader={<></>}
                height="100%"
                dataLength={planOfCare.length}
              >
                {planOfCare.map((activityLog, index) => (
                  <Row index={index} row={activityLog} />
                ))}
              </InfiniteScroll>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};
export default PlanOfCarePresentation;
