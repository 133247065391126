import { useState, useCallback, useEffect, useMemo, useRef, useContext } from "react";
import { StatisticBoxProps } from "components/StatisticBox/props";
import StatisticBox from "components/StatisticBox/statistic-box.component";
import { navigatorStatsItems } from "shared/fakeData";
import Header from "components/Header/header.component";
import DatePicker from "components/DatePicker/date-picker.component";
import Breadcrumb from "components/Breadcrumb/breadcrumb.component";
import { useHistory, useLocation, useParams } from "react-router";
import GenericTabs from "components/GenericTabs/generic-tabs.component";
import Button from "components/Button/button.component";

import { useDispatch, useSelector } from "react-redux";

import SidePopup from "components/SidePopup/side-popup.component";
import "./navigator-detail.styles.scss";
import Checkbox from "components/Checkbox/checkbox.component";
import { checkboxData } from "./common/constant";
import { data } from "components/Modal/TranscriptionModal/demoData";
import TranscriptionModal from "components/Modal/TranscriptionModal/transcription-modal.component";
import { getColonSeparatedDuration, getEndDate, getStartDate, isEmptyString } from "shared/methods/utilityFunctions";

import {
  inboundNavigatorDashboardCallTableColumns,
  inboundNavigatorManagerDashboardCallTableColumns,
  navigatorDashboardCallTableColumns,
  navigatorDashboardMessageTableColumns,
} from "shared/constant/table";
import moment from "moment";
import { CallSidePopup } from "components/SidePopup/CallSidePopup/call-side-popup.component";
import { CommunicationTab } from "shared/enums";
import { ActiveTabName } from "components/GenericTabs/props";
import { getAuth } from "state/feature/auth/auth.slice";
import { getCallState, setOpenParticipantsPopup } from "state/feature/call/call.slice";
import {
  getAssignPhoneNumberListAsync,
  getNavigatorCallsAsync,
  getNavigatorForManagerAsync,
  getNavigatorStatsForManagerAsync,
} from "state/feature/navigator/navigator.action";
import {
  getNavigatorPatientContactAsync,
  getNavigatorPhysicianContactAsync,
} from "state/feature/contact/contact.action";
import { getNavigatorState, resetCalls, setSelectedNavigator } from "state/feature/navigator/navigator.slice";
import {
  getCommonState,
  setIsOpenCallModal,
  setIsOpenChatModal,
  setIsFilterModal,
} from "state/feature/common/common.slice";
import {
  getMessagingState,
  resetConversations,
  setConvRequestPayload,
  setOpenMessagingParticipantsPopup,
} from "state/feature/messaging/messaging.slice";
import { getAllConversationsAsync } from "state/feature/messaging/messaging.action";
import CallParticipants from "components/call-participants/call-participants";
import { SortingOrderType } from "shared/enums/sorting-types.enums";
import { IGetConversationsAsyncRequestPayload } from "./common/types";
import { PAGE_LIMIT } from "shared/constant/commonConstants";
import { resetContacts } from "state/feature/contact/contact.slice";
import { useAppDispatch } from "state/store";
import { OpenFrom } from "shared/enums/chat.enum";
import CallLogsTable from "./components/call-logs-table/call-logs-table.component";
import { ColumnType } from "shared/types/table.type";
import MessageTable from "./components/message-table/message-table.component";
import { IGetCallsAsyncRequestPayload } from "pages/dashboard/common/types";
import ConversationParticipants from "components/conversation-participants/conversation-participants.component";
import { CallingStatus } from "shared/enums/calling-status.enum";
import { CallContext } from "App";

const NavigatorDetail = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const authState = useSelector(getAuth);
  const history = useHistory();
  const params = useParams<any>();
  const { modal: modalState, twilioErrorCode } = useSelector(getCommonState);
  const callState = useSelector(getCallState);
  const navigatorState = useSelector(getNavigatorState);
  const messagingState = useSelector(getMessagingState);
  const [isDatePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const [activeTabName, setActiveTabName] = useState<ActiveTabName>(CommunicationTab.CALLS);
  const [isOpen, setIsOpen] = useState(false);
  const [activeToggleTitle] = useState("Patient");
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [initDateState, setInitDateState] = useState<any>(null);
  const [navigatorSaved, setNavigatorSaved] = useState(false);
  const [dateState, setDateState] = useState<any>([
    {
      startDate: moment().subtract(7, "day").toDate(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [serverError, setServerError] = useState({ error: false, message: "" });
  const [messageColumnsHeader, setMessageColumnsHeader] = useState<ColumnType[]>(navigatorDashboardMessageTableColumns);
  const [callsColumnsHeader, setCallsColumnsHeader] = useState<ColumnType[]>(navigatorDashboardCallTableColumns);
  const { ongoingCall: isOngoingCall } = useContext(CallContext);
  const conversationInitialState = {
    userID: sessionStorage.getItem("externalId") as string,
    searchKeyword: "",
    limit: PAGE_LIMIT,
    offset: 0,
    convStartDate: getStartDate(moment().subtract(7, "day").toDate()),

    convEndDate: getEndDate(new Date()),
    sortColumn: "conversationTime",
    sortOrder: SortingOrderType.DESC,
  };

  const callsInitialState = useMemo(() => {
    const startDate = getStartDate(moment().subtract(7, "day").toDate());
    const endDate = getEndDate(new Date());
    return {
      navigatorId: sessionStorage.getItem("externalId") as string,
      startDate: startDate,
      endDate: endDate,
      callDirection: "",
      callStatus: [],
      searchText: "",
      sortColumn: "calltime",
      sortOrder: SortingOrderType.DESC,
      limit: PAGE_LIMIT,
      offset: 0,
      isNavigatorManager: true,
    };
  }, []);

  const converationRequestPayload = useRef(conversationInitialState);
  const [isLoading, setIsLoading] = useState(true);
  const [callsRequestPayload, setCallsRequestPayload] = useState<IGetCallsAsyncRequestPayload>(callsInitialState);

  useEffect(() => {
    return () => {
      dispatch(resetCalls());
      dispatch(resetConversations());
    };
  }, [dispatch]);

  useEffect(() => {
    if (activeTabName === CommunicationTab.CALLS) {
      setCallsColumnsHeader(
        JSON.parse(
          JSON.stringify(
            [
              ...inboundNavigatorDashboardCallTableColumns.filter((column) => column.order !== 7),
              ...inboundNavigatorManagerDashboardCallTableColumns,
            ].sort((a, b) => a.order - b.order)
          )
        )
      );
    } else {
      setMessageColumnsHeader(JSON.parse(JSON.stringify(navigatorDashboardMessageTableColumns)));
    }
  }, [activeTabName]);

  useEffect(() => {
    if (serverError.error) {
      const interval = setInterval(() => {
        setServerError({ message: "", error: false });
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [serverError]);

  const setConversationRequestPayload = useCallback(
    async (parameters: IGetConversationsAsyncRequestPayload) => {
      const { convStartDate, convEndDate } = parameters;
      converationRequestPayload.current = parameters;
      dispatch(
        setConvRequestPayload({
          ...parameters,
          convStartDate: convStartDate,
          convEndDate: convEndDate,
        })
      );
      await dispatch(
        getAllConversationsAsync({
          ...parameters,
          convStartDate: convStartDate,
          convEndDate: convEndDate,
        })
      );
      setIsLoading(false);
    },
    [dispatch]
  );
  const getNavigatorCalls = () => {
    if (navigatorState.managerStats.selectedNavigator?.id) {
      const { startDate, endDate } = callsRequestPayload;
      dispatch(
        getNavigatorCallsAsync({
          ...callsRequestPayload,
          navigatorId: navigatorState.managerStats.selectedNavigator.id,
          startDate: startDate,
          endDate: endDate,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(resetContacts());
    dispatch(
      getNavigatorPatientContactAsync({
        id: authState.user.id,
        isCareManagerId: false,
        searchText: "",
        pageNumber: 0,
        pageSize: 1,
        sortColumn: "name",
        sortOrder: SortingOrderType.ASC,
      })
    );
    dispatch(
      getNavigatorPhysicianContactAsync({
        id: authState.user.id,
        isCareManagerId: false,
        searchText: "",
        pageNumber: 0,
        pageSize: 1,
        sortColumn: "name",
        sortOrder: SortingOrderType.ASC,
      })
    );
  }, [authState.user.id, isOpen, dispatch]);
  const pathname = location.pathname;

  useEffect(() => {
    if (navigatorSaved) {
      const { startDate, endDate } = callsRequestPayload;
      if (navigatorState.managerStats.selectedNavigator?.id) {
        dispatch(
          getNavigatorCallsAsync({
            ...callsRequestPayload,
            navigatorId: navigatorState.managerStats?.selectedNavigator?.id,
            startDate: startDate,
            endDate: endDate,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callsRequestPayload, navigatorSaved, dispatch]);

  useEffect(() => {
    if (callState.currentCall === null) {
      getNavigatorCalls();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callState.currentCall]);

  const getNavigatorStatsNavigator = useCallback(
    async (startDateValue = dateState[0]?.startDate, endDateValue = dateState[0]?.endDate) => {
      const navigatorManagerId = sessionStorage.getItem("navigatorIdForManager");
      if (navigatorManagerId !== null) {
        const requestPayload = {
          navigatorId: navigatorManagerId ?? "",
          startDate: getStartDate(startDateValue),
          endDate: getEndDate(endDateValue),
        };
        dispatch(getNavigatorForManagerAsync(requestPayload));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const getNavigatorStatsForManager = useCallback(
    async (startDateValue = dateState[0].startDate, endDateValue = dateState[0].endDate) => {
      if (startDateValue && endDateValue) {
        const result = await appDispatch(
          getNavigatorStatsForManagerAsync({
            managerId: authState.user.id,
            startDate: getStartDate(startDateValue),
            endDate: getEndDate(endDateValue),
          })
        ).unwrap();

        if (result.length > 0 && navigatorState.currentUserProfile) {
          const navigatorSelected: any = result.filter((item: any) => {
            return item.name === params.navigatorName;
          })[0];
          if (navigatorSelected) {
            sessionStorage.setItem("navigatorIdForManager", navigatorSelected.id);
            sessionStorage.setItem("externalId", navigatorSelected.externalId);
            appDispatch(setSelectedNavigator(navigatorSelected));
            setNavigatorSaved(true);
          } else {
            history.replace("/dashboard");
            return new Promise((_, reject) => {
              reject(400);
            });
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authState.user.id, navigatorState.currentUserProfile]
  );

  useEffect(() => {
    getNavigatorStatsForManager().then(() => {
      getNavigatorStatsNavigator();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNavigatorStatsForManager]);

  const getStatisticBoxData = (managerStatsData: any) =>
    navigatorStatsItems.map((item) => {
      switch (item.code) {
        case "total-call-duration":
          item.info = getColonSeparatedDuration(Number(Math.round(managerStatsData.totalCallDuration)));
          break;
        case "missed-calls":
          item.info = managerStatsData.missedCallsCount;
          break;
        case "voicemails":
          item.info = managerStatsData.pendingVoicemailsCount;
          break;
        case "unread-messages":
          item.info = managerStatsData.unreadMessagesCount;
          break;
      }
      return item;
    });

  const [statisticBoxData, setStatisticBoxData] = useState([]);

  useEffect(() => {
    if (navigatorState.managerStats.selectedNavigator) {
      const result = getStatisticBoxData(navigatorState.managerStats.selectedNavigator);
      setStatisticBoxData(result as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigatorState.managerStats.selectedNavigator]);

  useEffect(() => {
    const result = getStatisticBoxData({
      totalCallDuration: 0,
      missedCallsCount: 0,
      pendingVoicemailsCount: 0,
      unreadMessagesCount: 0,
    });
    setStatisticBoxData(result as any);
  }, []);

  const callAPIOnApply = (item: any) => {
    setDateState(item);
    setIsLoading(true);
    const startDate = getStartDate(item[0].startDate);
    const endDate = getEndDate(item[0].endDate);
    getNavigatorStatsNavigator(getStartDate(item[0].startDate), getEndDate(item[0].endDate));
    if (startDate !== null && endDate !== null) {
      if (activeTabName === CommunicationTab.CALLS) {
        dispatch(resetCalls());
        setCallsRequestPayload((prev) => {
          return {
            ...prev,
            offset: 0,
            startDate: startDate,
            endDate: endDate,
          };
        });
      } else {
        dispatch(resetConversations());
        setConversationRequestPayload({
          ...converationRequestPayload.current,
          offset: 0,
          convStartDate: startDate,
          convEndDate: endDate,
        });
      }
    }
  };

  const handleApplyClick = (item: any) => {
    callAPIOnApply(item);
    setDatePickerOpen(false);
  };

  const setToDefaultDate = (tab = activeTabName) => {
    const start = moment().subtract(7, "day").startOf("day").toDate();
    const end = new Date();
    setDateState([
      {
        startDate: start,
        endDate: end,
        key: "selection",
      },
    ]);
    setInitDateState([
      {
        startDate: start,
        endDate: end,
        key: "selection",
      },
    ]);

    if (tab === CommunicationTab.CALLS) {
      dispatch(resetCalls());
      dispatch(resetConversations());
      setCallsRequestPayload({ ...callsInitialState });
    } else {
      dispatch(resetConversations());
      setConversationRequestPayload(conversationInitialState);
    }
  };

  const handleClearButtonClick = (val: boolean) => {
    setDatePickerOpen(val);
    setIsLoading(true);
    const start = moment().subtract(7, "day").startOf("day").toDate();
    const end = new Date();
    setDateState([
      {
        startDate: start,
        endDate: end,
        key: "selection",
      },
    ]);
    setInitDateState([
      {
        startDate: start,
        endDate: end,
        key: "selection",
      },
    ]);

    if (activeTabName === CommunicationTab.CALLS) {
      dispatch(resetCalls());
      setCallsRequestPayload((prev) => {
        return { ...prev, startDate: callsInitialState.startDate, endDate: callsInitialState.endDate };
      });
    } else {
      dispatch(resetConversations());
      setConversationRequestPayload({
        ...converationRequestPayload.current,
        convStartDate: conversationInitialState.convStartDate,
        convEndDate: conversationInitialState.convEndDate,
      });
    }
  };

  const handleDateRangeSelect = (item: any) => {
    setDateState([item]);
  };

  const onClickTabName = (tabName: ActiveTabName) => {
    if (activeTabName !== tabName) {
      setActiveTabName(tabName);
      setToDefaultDate(tabName);
      getNavigatorStatsNavigator();
    }
  };

  const handleMakeACall = async () => {
    const outboundPhoneData = await appDispatch(getAssignPhoneNumberListAsync()).unwrap();
    if (outboundPhoneData && outboundPhoneData.length > 0) {
      dispatch(setIsOpenCallModal(true));
    }
  };
  const handleComposeAMessage = async () => {
    dispatch(setIsOpenChatModal({ isOpen: true, openFrom: OpenFrom.COMPOSE }));
  };

  const refreshMessageList = () => {
    setConversationRequestPayload({
      ...converationRequestPayload.current,
    });
  };

  useEffect(() => {
    if (callState.callStatusText === "Initializing" && callState.currentCall === null) {
      setTimeout(() => {
        setCallsRequestPayload(callsInitialState);
      }, 1000);
    }
  }, [callState.callStatusText, callState.currentCall, callsInitialState]);

  const handleMessagesSorting = (selectedColumn: ColumnType) => {
    dispatch(resetConversations());
    setIsLoading(true);
    const index = messageColumnsHeader.findIndex((x) => x.key === selectedColumn.key);
    let sort: SortingOrderType = SortingOrderType.DEFAULT;
    switch (messageColumnsHeader[index].sortOrder) {
      case SortingOrderType.DESC:
      case SortingOrderType.DEFAULT:
        sort = SortingOrderType.ASC;
        break;
      case SortingOrderType.ASC:
        sort = SortingOrderType.DESC;
        break;
    }
    const tempHeaders = [...messageColumnsHeader];
    tempHeaders.forEach((header) => (header.sortOrder = SortingOrderType.DEFAULT));
    tempHeaders[index].sortOrder = sort;
    setMessageColumnsHeader(tempHeaders);
    converationRequestPayload.current = {
      ...converationRequestPayload.current,
      sortColumn: selectedColumn.key,
      sortOrder: sort,
    };
    dispatch(
      setConvRequestPayload({
        ...converationRequestPayload.current,
        offset: 0,
        sortColumn: selectedColumn.key,
        sortOrder: sort,
      })
    );
    setConversationRequestPayload({
      ...converationRequestPayload.current,
      offset: 0,
      sortColumn: selectedColumn.key,
      sortOrder: sort,
    });
  };

  const handleCallsSorting = (selectedColumn: ColumnType) => {
    dispatch(resetCalls());
    const index = callsColumnsHeader.findIndex((x) => x.key === selectedColumn.key);
    let sort: SortingOrderType = SortingOrderType.DEFAULT;
    switch (callsColumnsHeader[index].sortOrder) {
      case SortingOrderType.DESC:
      case SortingOrderType.DEFAULT:
        sort = SortingOrderType.ASC;
        break;
      case SortingOrderType.ASC:
        sort = SortingOrderType.DESC;
        break;
    }
    const tempHeaders = [...callsColumnsHeader];
    tempHeaders.forEach((header) => (header.sortOrder = SortingOrderType.DEFAULT));
    tempHeaders[index].sortOrder = sort;
    setCallsColumnsHeader(tempHeaders);
    setCallsRequestPayload((prev) => {
      return {
        ...prev,
        offset: 0,
        sortColumn: selectedColumn.key,
        sortOrder: sort,
      };
    });
  };

  const handleMessageIncrementPage = () => {
    setConversationRequestPayload({
      ...converationRequestPayload.current,
      offset: converationRequestPayload.current.offset + PAGE_LIMIT,
    });
  };

  const handleCallsIncrementPage = () => {
    setCallsRequestPayload((prev) => {
      return {
        ...prev,
        offset: prev.offset + PAGE_LIMIT,
      };
    });
  };

  const checkIfButtonDisabled = () => {
    return (
      twilioErrorCode !== null ||
      (callState && callState?.currentCall) ||
      isOngoingCall ||
      localStorage.getItem("callStatus") === CallingStatus.CONNECTED ||
      localStorage.getItem("callStatus") === CallingStatus.RINGING
    );
  };

  return (
    <div id="navigator-detail-container">
      <Header className="navigator-detail">
        <Breadcrumb pathname={pathname} />
        <div />
        <div>
          <DatePicker
            onTap={(val) => {
              setDatePickerOpen(val);
            }}
            isOpen={isDatePickerOpen}
            onDateRangeSelect={(item) => handleDateRangeSelect(item)}
            handleClearButtonClick={handleClearButtonClick}
            handleApplyClick={handleApplyClick}
            maxDate={new Date()}
            dateFormat="mm/dd/yyyy"
            initialSelectedDates={initDateState}
          />
        </div>
      </Header>
      <div className="statistic-box-grid">
        {statisticBoxData?.map((stat: StatisticBoxProps, index) => {
          return (
            <StatisticBox
              key={index}
              image={stat.image}
              info={stat.info}
              code={stat.code}
              value={stat.value}
              size={stat.size}
              className={stat.className}
              heading={stat.heading}
            />
          );
        })}
      </div>
      <div className="all-filters-section">
        <GenericTabs
          tabsList={[
            {
              name: CommunicationTab.CALLS,
            },
            {
              name: CommunicationTab.MESSAGES,
            },
          ]}
          activeTabName={activeTabName}
          setActiveTabName={onClickTabName}
        />
        <div className="right-section">
          {activeTabName === CommunicationTab.CALLS && (
            <Button
              text={"Make a Call"}
              onClick={handleMakeACall}
              className={`green-button make-a-call-button ${checkIfButtonDisabled() ? "disabled" : "enabled"}`}
              disabled={checkIfButtonDisabled()}
            />
          )}
          {(sessionStorage.getItem("externalId") as string) === authState.user.id &&
            activeTabName !== CommunicationTab.CALLS && (
              <Button text={"Compose"} onClick={handleComposeAMessage} className="green-button make-a-call-button" />
            )}
        </div>
      </div>
      <div className="table-container">
        {activeTabName === CommunicationTab.CALLS ? (
          <CallLogsTable
            tableClassName="navigator-manager-patient-call-table"
            columns={callsColumnsHeader}
            currentTableData={navigatorState?.calls}
            searchText={""}
            handleColumnSort={handleCallsSorting}
            incrementPage={() => {
              handleCallsIncrementPage();
            }}
            getNavigatorCalls={getNavigatorCalls}
          />
        ) : (
          <MessageTable
            tableClassName="NavigatorMessageTable"
            isLoading={isLoading}
            columns={messageColumnsHeader}
            conversations={
              messagingState?.conversations?.length === 0
                ? []
                : messagingState?.conversations?.map(
                    ({
                      lastMessageDateTime,
                      lastMessageContent,
                      conversationID,
                      twilioConversationId,
                      type,
                      lastMessageSenderId,
                      participantDetails,
                      isLastNotificationRead,
                      patientDOB,
                      subject,
                    }) => {
                      const filteredParticipantDetails = participantDetails.filter(
                        (participant: any) =>
                          participant.userID !== navigatorState.managerStats.selectedNavigator?.externalId
                      );
                      const newParticipantName = filteredParticipantDetails
                        .map((participant: any) => `${participant?.fullName}`)
                        .sort()
                        .join(", ")
                        .trim();
                      return {
                        participants: newParticipantName,
                        dateTime: lastMessageDateTime,
                        message: lastMessageContent,
                        conversationId: conversationID,
                        twilioConversationId: twilioConversationId,
                        type: type,
                        lastMessageSenderId: lastMessageSenderId,
                        participantDetails: participantDetails,
                        isLastNotificationRead: isLastNotificationRead,
                        patientDOB,
                        subject: !isEmptyString(subject ?? "") ? subject : "-",
                      };
                    }
                  )
            }
            activeToggleTitle={activeToggleTitle}
            searchText={""}
            getMoreMessages={refreshMessageList}
            handleColumnSort={handleMessagesSorting}
            incrementPage={() => {
              handleMessageIncrementPage();
            }}
          />
        )}
      </div>

      <SidePopup
        isOpen={callState.openParticipantsPopup}
        heading="Call Participants"
        contentClass="filter-content-container"
        className="filter-side-popup"
        onClose={() => dispatch(setOpenParticipantsPopup(false))}
      >
        <CallParticipants />
      </SidePopup>

      <SidePopup
        isOpen={messagingState.openMessagingParticipantsPopup}
        heading="Patient Details"
        contentClass="filter-content-container"
        className="filter-side-popup call-participants-popup"
        onClose={() => dispatch(setOpenMessagingParticipantsPopup(false))}
      >
        <ConversationParticipants />
      </SidePopup>

      <SidePopup
        isOpen={modalState.isOpenFilterModal}
        onClose={() => dispatch(setIsFilterModal(false))}
        contentClass="filter-content-container"
        className="filter-side-popup"
        heading="Filter"
      >
        <div className="filter-content">
          <div className="status">Status</div>
          <div className="checkbox-container">
            {checkboxData.map(({ name, id, value }) => {
              return (
                <div className="checkboxes" key={id}>
                  <Checkbox name={name} value={value} id={id} onChange={() => {}} isChecked={false} />
                </div>
              );
            })}
          </div>
          <div className="filter-buttons">
            <Button onClick={() => {}} text="Apply" className="green-button apply-button" />
            <Button onClick={() => {}} text="Clear" className="white-button clear-button" />
          </div>
        </div>
      </SidePopup>

      <CallSidePopup
        isOpen={modalState.isOpenCallModal}
        heading="Make a Call"
        isDropdownVisible={isDropdownVisible}
        setIsDropdownVisible={setIsDropdownVisible}
      />

      <TranscriptionModal
        isOpen={modalState.isOpenTranscriptionModal}
        heading="Kristin Watson Recording Transcripts  |  27 Aug 2022"
        transcript={data}
      />
    </div>
  );
};

export default NavigatorDetail;
