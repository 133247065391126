import React from "react";
import { ToogleProps } from "./props";
import "./toggle.styles.scss";

const Toggle = ({
  toggleLeftTitle,
  toggleRightTitle,
  activeToggleTitle,
  changeActiveToggle,
  showNoTitle,
  isDisabled,
}: ToogleProps) => {
  return (
    <div className="toggleContainer">
      <div className={`toggleTitle ${toggleLeftTitle === activeToggleTitle ? "active" : ""}`}>
        {showNoTitle ? "" : toggleLeftTitle}
      </div>
      <label className="switch">
        <input
          className="toggle-input"
          type="checkbox"
          onChange={() => changeActiveToggle(toggleRightTitle === activeToggleTitle)}
          checked={toggleRightTitle === activeToggleTitle}
          disabled={isDisabled}
        />
        <span className={`slider round ${isDisabled ? "slider-disabled" : ""}`}></span>
      </label>
      <div
        className={`toggleTitle toogle-title-right ${isDisabled ? "toggle-disabled" : ""} ${
          toggleRightTitle === activeToggleTitle ? "active" : ""
        }`}
      >
        {showNoTitle ? "" : toggleRightTitle}
      </div>
    </div>
  );
};

export default Toggle;
