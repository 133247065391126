import Button from "components/Button/button.component";
import { useSelector } from "react-redux";
import { getCommonState } from "state/feature/common/common.slice";
import Modal from "../modal.component";
import CustomDropDown from "components/CustomDropdown/custom-dropdown.component";
import "./patient-status-modal.styles.scss";
import { isEmptyString } from "shared/methods/utilityFunctions";
import { PatientStatusModalComponentProps } from "./patient-status-modal.types";
import { getEpisodesSlice } from "state/feature/episodes/episodes.slice";
import { careUtilizationEpisodeStatuses, PatientEpisodeStatus } from "./patient-status-modal-constants";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import RadioInputGroup from "components/RadioInputGroup/radio-input-group.component";

function PatientStatusModalPresentation(props: PatientStatusModalComponentProps) {
  const { modal } = useSelector(getCommonState);
  const { patientEpisodeStatus, isPatientStatusPopupLoading } = useSelector(getEpisodesSlice);
  const {
    handleOnSubmit,
    handleChange,
    handleChangeNote,
    handleOnCancel,
    isDisabledSubmitButton,
    isLoading,
    isSubmitButtonPressed,
    notes,
    onChangeCareUtilization,
    careUtilizationValue,
    careUtilizationVerifiedInputs,
  } = props;

  return (
    <Modal isOpen={modal.isOpenPatientStatusModal} className="center patient-status-popup">
      {isPatientStatusPopupLoading && (
        <div className={`patient-status-container`}>
          <CommonState type={CommonStateType.LOADING} />
        </div>
      )}
      {!isPatientStatusPopupLoading && (
        <div className={`patient-status-container`}>
          <div>
            <div className="header">Patient Episode Status</div>
            <div className="content">
              <div className="episode-dropdown-content-title">Current Episode Status</div>
              <CustomDropDown
                placeholder="Select"
                dropDownMenuItems={patientEpisodeStatus.intakeStatuses.map((intakeStatus) => {
                  return { key: intakeStatus.id, name: intakeStatus.name };
                })}
                handleValueChanges={(option) => {
                  handleChange("episode-status", option);
                }}
                value={{
                  name: patientEpisodeStatus?.intakeStatus.name,
                  key: patientEpisodeStatus?.intakeStatus.key.toString(),
                }}
                idFieldName="key"
                dropDownBoxClass="status-dropdown"
                selectionClass="status-selection"
                dropDownContainerClass={`status-dropdown-container`}
                isDisabled={false}
              />
              <div className="episode-dropdown-content-title">Status Change Reason</div>
              <CustomDropDown
                placeholder="Select"
                dropDownMenuItems={patientEpisodeStatus.statusChangeReasons.map((reason) => {
                  return { key: reason.id, name: reason.name };
                })}
                handleValueChanges={(option) => {
                  handleChange("status-change", option);
                }}
                value={
                  patientEpisodeStatus.statusChangeReasons.length === 0
                    ? { key: "not-required", name: "Not Required" }
                    : {
                        key: patientEpisodeStatus.statusChangeReason.key,
                        name: patientEpisodeStatus.statusChangeReason.name,
                      }
                }
                idFieldName="key"
                dropDownBoxClass="result-dropdown"
                selectionClass="result-selection"
                dropDownContainerClass={`result-dropdown-container`}
                isDisabled={patientEpisodeStatus.statusChangeReasons.length === 0}
                isError={
                  isSubmitButtonPressed &&
                  !isEmptyString(patientEpisodeStatus.intakeStatus.name) &&
                  patientEpisodeStatus.intakeStatus.name !== PatientEpisodeStatus.CLOSED &&
                  patientEpisodeStatus.intakeStatus.name !== PatientEpisodeStatus.NEW &&
                  isEmptyString(patientEpisodeStatus.statusChangeReason.name)
                }
                message="Select status change reason"
              />
              {(patientEpisodeStatus?.intakeStatus.name === careUtilizationEpisodeStatuses.DECEASED ||
                patientEpisodeStatus?.intakeStatus.name === careUtilizationEpisodeStatuses.DISCHARGED) && (
                <RadioInputGroup
                  labelText="Has Care Utilization been verified?"
                  errorMessage="Select an option"
                  shouldDisplayErroronDisabled
                  showError={isSubmitButtonPressed && careUtilizationValue == null}
                  radioList={careUtilizationVerifiedInputs}
                  handleInputChange={onChangeCareUtilization}
                  containerClassName="care-utilization-verified"
                />
              )}
              <div className="episode-dropdown-content-title">Additional Info to Status Change</div>
              <textarea
                className="text-area"
                id={`textarea`}
                name={`textarea`}
                maxLength={2000}
                cols={44}
                rows={5}
                value={notes}
                placeholder="Enter any additional information for your answer"
                onChange={(event) => {
                  handleChangeNote(event.target.value);
                }}
              />
              {notes.length > 0 && <div className="note-length">{notes.length} / 2000</div>}
            </div>
          </div>
          <div className="button-section">
            <Button text="Cancel" className="cancel-button" onClick={handleOnCancel} />
            <Button
              text="Submit"
              disabled={isDisabledSubmitButton}
              className="green-button submit-button"
              onClick={handleOnSubmit}
              showLoader={isLoading}
            />
          </div>
        </div>
      )}
    </Modal>
  );
}

export default PatientStatusModalPresentation;
